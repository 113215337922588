<template>
    <div class="attend">
        <Crumbs></Crumbs>
        <el-row class="attend-top">
            <!-- 搜索 -->
            <div class="attend-top-left">
                <span class="day">日期选择：</span>
                <el-date-picker v-model="parameter.attendanceDate" type="date" value-format="timestamp" placeholder="选择日期"
                    :clearable="false" @change="getData" />
            </div>
            <!-- 更新|导出 按钮 -->
            <div class="attend-top-right">
                <el-button class="attend-top-btn1" size="small" type="primary" @click="edit">更新</el-button>
                <el-button class="attend-top-btn2" size="small" type="primary" @click="downloadExcel">导出</el-button>
            </div>
        </el-row>

        <!-- 表格 -->
        <el-row class="attend-btm">
            <table id="attend-table">
                <tbody>
                    <tr>
                        <th rowspan="5" class="table-zz" style="width: 6%;">考勤状况分析</th>
                    </tr>
                    <tr>
                        <th class="table-top" style="width: 7%;">
                            <span class="right"><i style="display: block;float: right;">部门</i></span>
                            <span class="slash1"></span>
                            <span class="left" style=""><i
                                    style="display: block;float: left;margin-top: 15px;">项目</i></span>
                        </th>
                        <th class="table-top" :style="{ width: 85 / tableData[0].length + '%' }" style="padding: 0px"
                            v-for="item in  tableData[0] ">
                            {{ item }}
                        </th>
                    </tr>
                    <tr v-for=" i  in  3 ">
                        <th v-for=" item  in  tableData[i] ">{{ item }}</th>
                    </tr>
                </tbody>
            </table>
        </el-row>

        <!-- 更新弹窗 -->
        <el-dialog :append-to-body="true" :title="title" :visible.sync="showEditDialog" width="50%" :loading="loading">
            <el-form ref="form" label-width="50px">
                <el-form-item label="日期" prop="attendanceDate" ref="attendanceDate">
                    <el-date-picker value-format="timestamp" v-model="attendanceDate" type="date" :clearable="false"
                        placeholder="请选择时间" style="width: 26%;" @change="changeTime"></el-date-picker>
                </el-form-item>
                <div style="overflow-y: auto; height: 500px;width: 100%;padding: 0 10px;">
                    <el-descriptions v-for="item in form" :title="item.deptName" direction="vertical" :column="3" border
                        style="margin-bottom: 22px;">
                        <el-descriptions-item label="考勤人数">
                            <el-form-item label-width="0px" style="width:100%" prop="attendanceNum" ref="attendanceNum">
                                <el-input v-model="item.attendanceNum" onkeyup="value=value.replace(/[^\d]/g,'')"
                                    placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-descriptions-item>
                        <el-descriptions-item label="缺卡人数">
                            <el-form-item label-width="0px" style="width:100%" prop="absenceNum" ref="absenceNum">
                                <el-input v-model="item.absenceNum" onkeyup="value=value.replace(/[^\d]/g,'')"
                                    placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-descriptions-item>
                        <el-descriptions-item label="迟到人数">
                            <el-form-item label-width="0px" style="width:100%" prop="lateNum" ref="lateNum">
                                <el-input v-model="item.lateNum" onkeyup="value=value.replace(/[^\d]/g,'')"
                                    placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog = false">取 消</el-button>
                <el-button type="primary" @click="submit" :loading="submitLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            parameter: { attendanceDate: new Date(new Date().toLocaleDateString()).getTime() }, //日期
            tableData: [], //存放展示的数据
            deptList: [],//部门列表
            form: [], //弹窗填写的数据
            showEditDialog: false, //弹窗可见
            loading: false,
            submitLoading: false,
            attendanceDate: new Date(new Date().toLocaleDateString()).getTime(), //弹窗时间
            deleteId: [], //删除具体考勤信息的id
            title: ""
        }
    },
    created() {
        this.getDeptList();
        this.getData();//获取列表
    },
    watch: {
    },
    methods: {
        // 获取列表
        getData() {
            this.$api.user.attendance(this.parameter).then(res => {
                this.tableData = [[], ['考勤人数'], ['缺卡人次'], ['迟到人次']];
                // 获取的数据分为四个数组方便展示
                if (res.data.length < 1) {
                    this.tableData[0].push('');
                    this.tableData[1].push('');
                    this.tableData[2].push('');
                    this.tableData[3].push('');
                } else {
                    for (let item of res.data) {
                        this.tableData[0].push(item.deptName);
                        this.tableData[1].push(item.attendanceNum);
                        this.tableData[2].push(item.absenceNum);
                        this.tableData[3].push(item.lateNum);
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },

        //导出
        downloadExcel() {
            this.$api.user.attendanceExport(this.parameter).then((res) => {
                var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
                var url = URL.createObjectURL(blob)
                var exportLink = document.createElement('a')
                exportLink.setAttribute('download', '考勤记录.xlsx')
                exportLink.href = url
                document.body.appendChild(exportLink)
                exportLink.click()
                document.body.removeChild(exportLink) //下载完成移除元素
                URL.revokeObjectURL(exportLink.href) //释放掉blob对象
                this.$message({
                    message: '导出成功!',
                    type: 'success',
                })
            }).catch((err) => {
                console.log(err);
            })
        },

        //修改时间更新修改的内容
        changeTime() {
            let temp = this.deptList.deepClone();
            this.deleteId = [];
            this.$api.user.attendance({ attendanceDate: this.attendanceDate }).then(res => {
                for (let item of res.data) {
                    if (item.id) {
                        this.deleteId.push(item.id);
                        let index = temp.findIndex(function (a) {
                            return a.deptId == item.deptId;
                        })
                        temp[index] = item;
                    }
                }
                this.form = temp;
                if (this.deleteId.length > 0) {
                    this.title = "修改考勤表";
                } else {
                    this.title = "新增考勤表";
                }
                this.loading = false;
            }).catch(err => {
                console.log(err);
            });
        },

        //修改
        edit() {
            this.loading = true;
            this.deleteId = [];
            this.attendanceDate = new Date(new Date().toLocaleDateString()).getTime();
            this.changeTime();
            this.showEditDialog = true;
        },

        /** 加载 "部门" 列表数据 **/
        getDeptList() {
            this.$api.sysDept.listDept()
                .then((res) => {
                    this.deptList = [];
                    for (let item of res.data) {
                        this.deptList.push({
                            deptId: item.id,
                            deptName: item.deptName,
                            absenceNum: '',
                            attendanceNum: '',
                            lateNum: ''
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        //提交
        submit() {
            this.submitLoading = true;
            for (let item of this.deleteId) {
                this.$api.user.attendanceDelete(item).catch((err) => { console.log(err); });
            }
            for (let item of this.form) {
                item.attendanceDate = this.attendanceDate;
                item.id = null;
            }
            this.$api.user.attendanceAdd(this.form)
                .then((res) => {
                    this.$message.success("新增成功！");
                    this.submitLoading = false;
                    this.showEditDialog = false;
                    this.getData();
                })
                .catch((err) => {
                    console.log(err);
                    this.submitLoading = false;
                });
        },
    }
}
</script>
<style scoped lang="scss">
.attend {
    padding: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .attend-top {
        margin-bottom: 5px;

        .attend-top-left {
            margin: 5px;
            height: 40px;

            .day {
                font-size: 20px;
                margin-right: 20px;
            }
        }

        .attend-top-right {
            position: relative;

            .attend-top-btn1,
            .attend-top-btn2 {
                position: absolute;
                right: 110px;
                top: -35px;
                width: 90px;
            }

            .attend-top-btn2 {
                right: 8px;
            }
        }
    }
}

.attend-btm {
    padding-left: 10px;
    width: 1610px;

    #attend-table {
        width: 100%;
        text-align: center;

        th,
        tr,
        td {
            border: 1px solid #080808;
            color: #080808;
        }

        td,
        th {
            height: 50px;
            padding: 20px;
        }

        .table-zz {
            background-color: #fde9d9;
            width: 100px;
        }

        .table-top {
            background-color: #dce6f1;
        }
    }
}

/* 斜线 */
.slash1 {
    position: absolute;
    display: block;
    top: 0;
    left: 108px;
    width: 140px;
    height: 1px;
    background-color: black;
    transform: rotate(35deg);
    transform-origin: top left;
}

.left {
    padding-bottom: 100px;
}

/deep/ .el-descriptions__header {
    margin-bottom: 1px;
}
</style>